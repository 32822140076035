import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormSelectField } from 'components/duex/FormSelectField';
import { Heatmap } from 'components/duex/Heatmap';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { Tabs } from 'components/duex/Tabs';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import { useTabs } from 'hooks/useTabs';
import { useUserContext } from 'hooks/useUserContext';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import {
    SettingsCheckbox,
    SettingsItem,
    SettingsNumberField,
    SettingsSection,
    SettingsTextField,
} from 'modules/settings/settings-components';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Membership } from 'types';
import Team from 'types/team.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

const plans = [
    {
        planName: 'Free Tier',
    },
    {
        planName: 'Scale Up',
    },
    {
        planName: 'Enterprise',
    },
];

const defaultTabs = [
    {
        label: 'Overview',
        isActive: true,
        key: 'OVERVIEW',
    },
    {
        label: 'Activity',
        isActive: false,
        key: 'ACTIVITY',
    },
];

interface Activity {
    date: string;
    count: number;
}
interface UserActivity extends Membership {
    activity: Activity[];
    errors: Activity[];
}

interface ActivityStats {
    userActivity: UserActivity[];
    totalActivity: {
        activity: Activity[];
        errors: Activity[];
    };
}

export const TeamViewModal = ({
    originalSettings,
    closeModal,
}: {
    originalSettings: Team;
    closeModal: () => void;
}): ReactElement => {
    const [settings, setSettings] = useState<Team>({});
    const [saving, setSaving] = useState(false);
    const { tabs, changeTab, selectedTab } = useTabs(defaultTabs);
    const [searching, setSearching] = useState(false);
    const [activity, setActivity] = useState<ActivityStats>(null);

    const { user } = useUserContext();

    const hasChanges = !_isEqual(settings, originalSettings);

    const undoChanges = async () => {
        setSettings(originalSettings);
    };

    const getChangedProperties = () => {
        const keys = Object.keys(settings);

        const changedKeys = keys.filter((key) => {
            return !_isEqual(settings[key], originalSettings[key]);
        });

        return changedKeys;
    };

    const saveChanges = async () => {
        try {
            setSaving(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.ADMIN_TEAM_UPDATE);

            const changedKeys = getChangedProperties();
            const changes = _pick(settings, changedKeys);

            await API.post(url, {
                teamId: originalSettings.teamId,
                ...changes,
            });

            closeModal();

            setSaving(false);
        } catch (err) {
            showError('There was an error saving your update', err);
            setSaving(false);
        }
    };

    const updateSetting = (propertyName: string) => {
        return (newValue) => {
            setSettings({
                ...settings,
                [propertyName]: newValue,
            });
        };
    };

    const fetchActivity = async () => {
        try {
            setSearching(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.ADMIN_TEAM_ACTIVITY);

            const results = await API.post(url, {
                teamId: originalSettings.teamId,
            });

            if (results && results.data) {
                setActivity(results.data);
            }
            setSearching(false);
        } catch (err) {
            showError('There was an error fetching the teams activity', err);
            setSearching(false);
        }
    };

    const recalculateIndexForTeam = async () => {
        if (
            confirm(
                'This is destructive and breaks the ability to use suggestions and search until the index recalculates',
            )
        ) {
            try {
                const url = ENDPOINTS.getLiteralUrl(`/admin/actions/flush-elastic/${originalSettings.teamId}`);

                const results = await API.post(url);
                if (results && results.data) {
                    showSuccess('Request placed');
                }
            } catch (err) {
                showError('There was an error placing that request', err);
            }
        }
    };

    useEffect(() => {
        setSettings(originalSettings);
    }, [originalSettings]);

    useEffect(() => {
        if (selectedTab.key === 'ACTIVITY') {
            fetchActivity();
        }
    }, [selectedTab.key]);

    return (
        <Modal title={`Team: ${settings.name}`} width="w-90-percent" height="full" closeModal={closeModal}>
            <Tabs tabItems={tabs} onChange={changeTab} />
            {selectedTab.key === 'OVERVIEW' && (
                <>
                    <div className="my-32">
                        {user.emailAddress === 'robin+admin@garnham.com.au' && (
                            <Button label="Refresh Index" buttonType="SECONDARY" onClick={recalculateIndexForTeam} />
                        )}

                        <SettingsSection title="Summary" />

                        <div className="text-gray-500">
                            <SettingsItem>Created: {moment(settings.createdAt).format('Do MMM YYYY')}</SettingsItem>
                        </div>

                        <SettingsItem>
                            <SettingsTextField
                                description="Website URL"
                                currentValue={settings.companyWebsite || ''}
                                disabled={true}
                                placeholder="The company's website"
                            />
                        </SettingsItem>
                    </div>
                    <div className="mb-32">
                        <SettingsSection title="Financial" />

                        <SettingsItem>
                            <SettingsCheckbox description="Account Enabled" currentValue={true} disabled={true} />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Current Trial"
                                currentValue={settings.currentTrial || false}
                                onChange={updateSetting('currentTrial')}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsTextField
                                description="Paid Till Date"
                                currentValue={settings.paidTill || ''}
                                onChange={updateSetting('paidTill')}
                                placeholder="The paid till date"
                            />
                        </SettingsItem>
                        {settings.redemptionCodes && settings.redemptionCodes.length > 0 && (
                            <div>
                                <SettingsSection title="Promotions" />
                                <div className="mx-16 mb-8">Claimed Redemption Codes</div>
                                {settings.redemptionCodes.map((code) => {
                                    return (
                                        <div
                                            className="flex flex-row justify-between gap-16 rounded-md p-16 text-14 leading-24 text-gray-600 hover:bg-gray-50"
                                            key={code.redemptionCode}
                                        >
                                            <div className="flex flex-row gap-16">
                                                <div className="">{code.redemptionCode}</div>
                                            </div>

                                            <div>Claimed on {moment(code.claimedAt).format('d MMM yy')}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="mb-32">
                        <SettingsSection title="Plan" />
                        <SettingsItem>
                            <FormSelectField
                                includeLabel={false}
                                required={false}
                                defaultValue={'Free Tier'}
                                errorMessage="Please select a team"
                                optionLabel="planName"
                                optionValue="planName"
                                label="Selected Plan"
                                valueChanged={updateSetting('planName')}
                                placeholder="Provide a plan"
                                items={plans}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsNumberField
                                description={`Paid User Count`}
                                currentValue={settings.paidUserCount || 0}
                                onChange={updateSetting('paidUserCount')}
                                placeholder="Provide a number"
                                minValue={0}
                                maxValue={10000}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsNumberField
                                description={`Hard User Limit`}
                                currentValue={settings.hardUserLimit || 0}
                                onChange={updateSetting('hardUserLimit')}
                                placeholder="Provide a number"
                                minValue={0}
                                maxValue={10000}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Enable AI Assistant Integration"
                                currentValue={settings.allowChatGptIntegration || false}
                                onChange={updateSetting('allowChatGptIntegration')}
                            />
                        </SettingsItem>
                    </div>
                    <div className="mb-32">
                        <SettingsSection title="Advanced Settings" />
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Enable Divisions"
                                currentValue={settings.enableDivisions || false}
                                onChange={updateSetting('enableDivisions')}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Enable Enterprise API"
                                currentValue={settings.enableApi || false}
                                onChange={updateSetting('enableApi')}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Enable Buyer Projects"
                                currentValue={settings.enableBuyerProjects || false}
                                onChange={updateSetting('enableBuyerProjects')}
                            />
                        </SettingsItem>
                    </div>

                    <div className="mb-32">
                        <SettingsSection title="Customer Configuration (Read-only)" />
                        <SettingsItem>
                            <SettingsCheckbox
                                description="AI Assistant"
                                currentValue={settings.enableChatGPTIntegration}
                                disabled={true}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Auto Create Trust Centre"
                                currentValue={settings.automaticallyCreateTrustCentre}
                                disabled={true}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Auto Check 'Include in Answer Library'"
                                currentValue={settings.defaultAnswersToFlowToLibrary}
                                disabled={true}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Enable Trust Centre"
                                currentValue={settings.enableTrustCentre}
                                disabled={true}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Flag new answers for Review"
                                currentValue={settings.importedAnswersRequireReview}
                                disabled={true}
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsNumberField
                                description={`Default Project Due Date`}
                                currentValue={settings.defaultDueDate}
                                placeholder="Provide a number"
                                disabled={true}
                            />
                        </SettingsItem>
                    </div>
                    <div className="mb-32">
                        <SettingsSection title="Single Sign-On" />

                        <SettingsItem>
                            <SettingsTextField
                                description={`SSO Alias (https://app.pearler.ai/login/${settings.ssoAlias})`}
                                currentValue={settings.ssoAlias || ''}
                                onChange={updateSetting('ssoAlias')}
                                placeholder="The URL parameter for their domain"
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsTextField
                                description={`Cognito Provider Name`}
                                currentValue={settings.ssoCognitoProviderName || ''}
                                onChange={updateSetting('ssoCognitoProviderName')}
                                placeholder="The Cognito Provider Name"
                            />
                        </SettingsItem>
                        <SettingsItem>
                            <SettingsTextField
                                description={`SSO Bound Domain`}
                                currentValue={settings.ssoDomain || ''}
                                onChange={updateSetting('ssoDomain')}
                                placeholder="The email domain that they want us to redirect"
                            />
                        </SettingsItem>
                    </div>
                    <ButtonBar className="mx-16 pb-32">
                        <Button
                            label="Save Changes"
                            disabled={!hasChanges || saving}
                            loading={saving}
                            onClick={saveChanges}
                        />
                        {hasChanges && (
                            <Button
                                label="Undo Changes"
                                disabled={!hasChanges || saving}
                                buttonType="SECONDARY"
                                onClick={undoChanges}
                            />
                        )}
                    </ButtonBar>
                </>
            )}
            {selectedTab.key === 'ACTIVITY' && (
                <>
                    {searching && <LoadingSpinner />}
                    {!searching && activity && (
                        <div className="grid gap-16 my-16">
                            <div className="text-xl text-blue-600">Team Usage</div>
                            <div className="border border-gray-50 rounded-md p-16 grid grid-cols-12">
                                <div className="col-span-4 flex flex-row gap-16">
                                    <div className="">Team Members: {activity.userActivity.length}</div>
                                </div>
                                <div className="col-span-4">
                                    {activity.totalActivity.activity.length > 0 && (
                                        <>
                                            <div className="text-gray-500 text-14 uppercase text-center">Actions</div>
                                            <Heatmap
                                                activity={activity.totalActivity.activity}
                                                startDate={moment().subtract(90, 'day')}
                                                height={'h-160'}
                                                showLegend={false}
                                                descriptor="Actions"
                                            />
                                        </>
                                    )}
                                    {activity.totalActivity.activity.length === 0 && (
                                        <div className="italic text-14 text-gray-400 text-center">No activity</div>
                                    )}
                                </div>
                                <div className="col-span-4">
                                    {activity.totalActivity.errors.length > 0 && (
                                        <>
                                            <div className="text-gray-500 text-14 uppercase text-center">Errors</div>
                                            <Heatmap
                                                activity={activity.totalActivity.errors}
                                                startDate={moment().subtract(90, 'day')}
                                                height={'h-160'}
                                                showLegend={false}
                                                descriptor="Errors"
                                            />
                                        </>
                                    )}
                                    {activity.totalActivity.errors.length === 0 && (
                                        <div className="italic text-gray-400 text-center text-14">No errors</div>
                                    )}
                                </div>
                            </div>
                            <div className="text-xl text-blue-600">Team Member Usage</div>
                            {activity.userActivity.map((membership) => {
                                return (
                                    <div className="border border-gray-50 rounded-md p-16 grid grid-cols-12">
                                        <div className="col-span-4 flex flex-row gap-16">
                                            <Avatar size={32} user={membership.member} />
                                            <div className="">
                                                <div className="">{membership.member.fullName}</div>
                                                <div className="text-gray-500 text-14">
                                                    {membership.member.emailAddress}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-4">
                                            {membership.activity.length > 0 && (
                                                <>
                                                    <div className="text-gray-500 text-14 uppercase text-center">
                                                        Actions
                                                    </div>
                                                    <Heatmap
                                                        activity={membership.activity}
                                                        startDate={moment().subtract(90, 'day')}
                                                        height={'h-160'}
                                                        showLegend={false}
                                                        descriptor="Actions"
                                                    />
                                                </>
                                            )}
                                            {membership.activity.length === 0 && (
                                                <div className="italic text-14 text-gray-400 text-center">
                                                    No activity
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-span-4">
                                            {membership.errors.length > 0 && (
                                                <>
                                                    <div className="text-gray-500 text-14 uppercase text-center">
                                                        Errors
                                                    </div>
                                                    <Heatmap
                                                        activity={membership.errors}
                                                        startDate={moment().subtract(90, 'day')}
                                                        height={'h-160'}
                                                        showLegend={false}
                                                        descriptor="Errors"
                                                    />
                                                </>
                                            )}
                                            {membership.errors.length === 0 && (
                                                <div className="italic text-gray-400 text-center text-14">
                                                    No errors
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};
